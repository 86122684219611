import api from "@/assets/api/api.js";
import moment from "moment";

const state = {
  af_upline: "",
  sf_upline: "",
};

const mutations = {
  SET_AF_UPLINE(state, upline) {
    state.af_upline = upline;
  },

  SET_SF_UPLINE(state, upline) {
    state.sf_upline = upline;
  },
};

const actions = {
  // async getReferralCode() {
  //   let response = await api.apiCall("GET", "/promotion/jwt/referral/code");

  //   if (response.status === "success") {
  //     return response.data;
  //   } else {
  //     throw response;
  //   }
  // },
  // async getReferralProgress({ commit }) {
  //   let response = await api.apiCall("GET", "/promotion/jwt/referral/progress");

  //   if (response.status === "success") {
  //     commit("SET_SF_UPLINE", response.data);
  //     return response.data;
  //   } else {
  //     throw response;
  //   }
  // },

  // async getReferralHistory(context, data) {
  //   let response = await api.apiCall(
  //     "GET",
  //     "/promotion/jwt/referral/history",
  //     data
  //   );

  //   if (response.status === "success") {
  //     return response.data;
  //   } else {
  //     throw response;
  //   }
  // },

  // async claimReferralCode(context, ref_code) {
  //   let response = await api.apiCall(
  //     "POST",
  //     "/promotion/jwt/claim/referralcode",
  //     ref_code
  //   );

  //   if (response.status !== "success") {
  //     throw response;
  //   }
  // },

  // async claimReferralReward(context, user_id) {
  //   let response = await api.apiCall(
  //     "POST",
  //     "/promotion/jwt/claim/referral",
  //     user_id
  //   );

  //   if (response.status !== "success") {
  //     throw response;
  //   }
  // },

  // async setReferralReminder(context, user_id) {
  //   let response = await api.apiCall(
  //     "POST",
  //     "/promotion/jwt/send/referral-reminder",
  //     user_id
  //   );

  //   if (response.status !== "success") {
  //     throw response;
  //   }
  // },

  // async checkReward(context) {
  //   let response = await api.apiCall(
  //     "POST",
  //     "/promotion/jwt/check/referralReward "
  //   );

  //   if (response.status !== "success") {
  //     throw response;
  //   }
  // },

  //Affiliate Program
  async getAffiliateCode(context) {
    let response = await api.apiCall("GET", "/promotion/jwt/affiliate/code");

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async getUserDetail({ commit }) {
    let response = await api.apiCall("GET", "/promotion/jwt/affiliate/user");

    if (response.status === "success") {
      commit("SET_AF_UPLINE", response.data);
      return response.data;
    } else {
      throw response;
    }
  },

  async getInviteesDetail(context, params) {
    let response = await api.apiCall(
      "GET",
      "/promotion/jwt/affiliate/paginate",
      params
    );

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async claimAffiliateCode(context, code) {
    let response = await api.apiCall(
      "POST",
      "/promotion/jwt/claim/affiliate",
      code
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async registerCode(context) {
    let response = await api.apiCall(
      "POST",
      "/promotion/jwt/claim/registerCode"
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async checkAffUser({ context, dispatch }) {
    let response = await api.apiCall(
      "GET",
      "/promotion/jwt/affiliate/user-exist"
    );

    if (response.status === "success") {
      if (response.data) {
        dispatch("registerAffRefCode");
      }
    } else {
      throw response;
    }
  },

  async checkRefUser({ context, dispatch, rootState }) {
    // if (this.$moment().format("X") < rootState.MY_referral.campaignEndTime) {
    let response = await api.apiCall(
      "GET",
      "/promotion/jwt/referral/user-exist "
    );

    if (response.status === "success") {
      if (response.data) {
        dispatch("registerAffRefCode");
      }
    } else {
      throw response;
    }
    // }
  },

  async registerAffRefCode(context) {
    let response = await api.apiCall(
      "POST",
      "/promotion/jwt/check/registered-code"
    );

    if (response.status !== "success") {
      throw response;
    }
  },
};

export const referral = {
  namespaced: true,
  state,
  mutations,
  actions,
};
