<template>
    <div class="main-body" id="txn-details">
        <div class="main-header">
            <button class="onboarding-back-arrow" @click="$emit('back')" v-if="isBackBtn">
                <v-icon>mdi-arrow-left</v-icon>
            </button>
            <div class="main-header-text">{{ details.title && `${details.title}` }}</div>
            <!-- <div class="main-header-text txn-details-transaction-type">{{ details.transaction_type && !details.title ? `${details.transaction_type}` : '' }}</div> -->
        </div>

        <div class="txn-details-body">
            <div id="txn-image">            <img :src="require(`@/assets/images/${details.image ? details.image : 'wallet/bank-icon.png'}`)"
                width="60px" class="txn-details-icon" v-if="details.isDeposit" />
            <img :src="`${details.image ? details.image : require('@/assets/images/wallet/bank-icon.png')}`"
                width="60px" class="txn-details-icon" v-else-if="details.isBankIcon" />
            <img :src="require(`@/assets/images/${details.image}`)" width="60px" class="txn-details-icon"
                v-else-if="details.isRewards" />
            <img :src="details.image" width="60px" class="txn-details-icon" v-else-if="!details.isFiat">
            <img :src="require(details.status === $t('Status.pending') 
                ? '@/assets/images/modal/pending3.png'
                : details.status === $t('Status.rejected') 
                    ? '@/assets/images/modal/rejected-icon.png'
                    : details.status === $t('Status.failed')  
                    ? '@/assets/images/modal/rejected-icon.png' 
                    : details.status === $t('Status.canceled') 
                    ? '@/assets/images/modal/rejected-icon.png' 
                    : '@/assets/images/modal/success.png')
                " width="60px" class="txn-details-icon" v-else /></div>
            
            <div class="txn-invest-image" v-if="details.dcaImage">
                <div v-for="coin in details.dcaImage" :key="coin.id">
                    <img :src="coin.image" width="60px" class="txn-details-icon"/>
                </div>
            </div>


            <div class="txn-details-subtitle">{{ details.subtitle }}</div>
            <!-- <div class="txn-details-subtitle" v-else-if="details.isFee">{{  $store.state.preferences.platform == 1 ? "RM" : "$" }}{{ numberSeparator(details.bank_charges, 2) }}</div> -->

            <div class="txn-details-caption">{{ details.caption }}</div>

            <!-- DEPOSIT FIAT -->
            <div v-if="!details.swift_code">
                <div v-if="$store.state.preferences.platform == 1 && details.transaction_type === 'deposit'">
                    <div class="txn-details-caption w-100" v-if="details.method === 'fpx'">{{
                        $t('Deposit.depositMYRCaption', {method: $t('Deposit.instantDepositFPX')}) }}</div>
                    <div class="txn-details-caption w-100" v-if="details.method === 'wallet'">{{
                        $t('Deposit.depositMYRCaption', {method: $t('Deposit.eWallet')}) }}</div>
                    <div class="txn-details-caption w-100" v-if="details.method === 'swift'">{{
                        $t('Deposit.depositMYRCaption', {method: $t('Deposit.bankTransfer')}) }}</div>
                </div>

                <div v-else>
                    <div class="txn-details-caption w-100" v-if="details.transaction_type === 'deposit'">{{
                        `${$t('Deposit.depositUSDCaption')}` }}</div>
                    <div class="txn-details-caption w-100"
                        v-if="details.transaction_type === 'bank_charges' || details.transaction_type === 'bank charges'">
                        {{ `${$t('Deposit.depositFeeCaption')}` }}</div>
                </div>
            </div>

            <!-- DEPOSIT FEE -->
            <div class="txn-details-caption w-100"
                v-if="details.transaction_type === 'deposit_fee' || details.transaction_type === 'deposit fee'">{{
                    `${$t('Deposit.depositFeeCaption')}` }}</div>

            <div class="txn-details-caption"
                v-if="details.transaction_type === 'withdrawal' && $store.state.preferences.platform == 1">
                {{ `${details.swift_code === 'DuitNow' ? $t('wallet.withdrawFiatDuitnowCaption')
                    : $t('wallet.withdrawFiatMYRCaption')}` }}
            </div>

            <!-- WITHDRAW FIAT -->
            <div class="txn-details-caption"
                v-if="details.transaction_type === 'withdrawal' && $store.state.preferences.platform == 2">
                {{ `${$t('wallet.withdrawFiatUSDCaption')}` }}
            </div>

            <div class="txn-details-caption"
                v-if="details.transaction_type === 'withdrawal_fee' || details.transaction_type === 'withdrawal fee'">
                {{ `${$t('wallet.withdrawFeeCaption')}` }}
            </div>

            <div class="txn-history-status" v-if="details.status">
                <div class="txn-history-dot" :class="returnStatusColor(details.status)">
                </div>
                <div class="txn-history-status-text text-bold" :class="returnStatusTextColor(details.status)">
                    {{ details.status }}
                </div>
            </div>

            <div class="txn-details-separator"></div>

            <div class="txn-details-question-container">
                <a :href="question.link" target="_blank" class="txn-details-question" v-if="question">
                    {{ question.title }}
                </a>
            </div>

            <div class="txn-details-body-content">
                <div :id="screenshot" class="w-100">
                <!-- DATE -->
                <div class="txn-details-row" v-if="details.date">
                    <div class="txn-details-row-title">{{ $t("wallet.date") }}</div>
                    <div class="txn-details-row-content">
                        {{ dateFormatter((details.date * 1000), "YYYY-MM-DD HH:mm:ss") }}
                    </div>
                </div>

                <!-- REQUESTED DATE -->
                <div class="txn-details-row" v-if="details.requested_date">
                    <div class="txn-details-row-title">{{ $t("wallet.requestedDate") }}</div>
                    <div class="txn-details-row-content">
                        {{ dateFormatter((details.requested_date * 1000), "YYYY-MM-DD HH:mm:ss") }}
                    </div>
                </div>
                
                <!-- REQUEST ID -->
                <div class="txn-details-row" v-if="details.request_id && !details.isFee">
                    <div class="txn-details-row-title">{{ $t("wallet.requestID") }}</div>

                    <div class="txn-details-row-content primary-color-text">
                        <div id="copyValue">
                            {{ details.request_id }}
                        </div>
                        <button @click.capture="copy(1, details.request_id)" class="copy-btn relative" id="request_id">
                            <v-tooltip v-model="show[1]" :disabled="true" bottom>
                                <template v-slot:activator="{ props }">
                                    <img v-bind="props" src="@/assets/images/wallet/copy.svg" />
                                </template>
                                <span>{{ $t("wallet.copied") }}</span>
                            </v-tooltip>
                        </button>
                    </div>
                </div>
                
                <!-- PLAN NAME -->
                <div class="txn-details-row" v-if="details.planName">
                    <div class="txn-details-row-title">{{ $t("AutoInvestPlan.planName") }}</div>
                    <div class="txn-details-row-content">
                        {{ details.planName }}
                    </div>
                </div>

                <!-- RATE -->
                <div class="txn-details-row" v-if="details.rate">
                    <div class="txn-details-row-title">{{ $t("wallet.rate") }}</div>
                    <div class="txn-details-row-content">
                        {{ details.rate }}
                    </div>
                </div>

                <!-- SEND TO -->
                <div class="txn-details-row" v-if="details.sendTo">
                    <div class="txn-details-row-title">{{ $t("Send.sendTo") }}</div>
                    <div class="txn-details-row-content txn-send-receive">
                        <div v-if="details.sendTo.email"> {{ details.sendTo.email }} </div>
                        <div v-if="details.sendTo.user_id"> {{ details.sendTo.user_id }} </div>
                        <div v-else> {{ details.sendTo }} </div>
                    </div>
                </div>

                <!-- RECEIVED FROM -->
                <div class="txn-details-row" v-if="details.receivedFrom">
                    <div class="txn-details-row-title">{{ $t("Send.receiveFrom") }}</div>
                    <div class="txn-details-row-content txn-send-receive">
                        <div v-if="details.receivedFrom.user_id"> {{ details.receivedFrom.user_id }} </div>
                        <div v-if="details.receivedFrom.email"> {{ details.receivedFrom.email }} </div>
                        <div v-else> {{ details.receivedFrom }} </div>
                    </div>
                </div>

                <!-- HATA USER ID / EMAIL -->
                <div class="txn-details-row" v-if="details.user">
                    <div class="txn-details-row-title">{{ $t("wallet.userIdEmail") }}</div>
                    <div class="txn-details-row-content">
                        {{ details.user }}
                    </div>
                </div>

                <!-- HATA USER ID -->
                <div class="txn-details-row" v-if="details.userId">
                    <div class="txn-details-row-title">{{ $t("Send.hataUserId") }}</div>
                    <div class="txn-details-row-content primary-color-text">
                        <div id="copyValue">
                            {{ details.userId }}
                        </div>
                        <button @click.capture="copy(0, details.userId)" class="copy-btn" id="userId">
                            <v-tooltip v-model="show[0]" :disabled="true" bottom>
                                <template v-slot:activator="{ props }">
                                    <img v-bind="props" src="@/assets/images/wallet/copy.svg" />
                                </template>
                                <span>{{ $t("wallet.copied") }}</span>
                            </v-tooltip>
                        </button>
                    </div>
                </div>

                <!-- HATA EMAIL -->
                <div class="txn-details-row" v-if="details.email">
                    <div class="txn-details-row-title">{{ $t("Send.hataEmail") }}</div>
                    <div class="txn-details-row-content">
                        {{ details.email }}
                    </div>
                </div>


                <!-- WALLET ADDRESS -->
                <div class="txn-details-row" v-if="details.address">
                    <div class="txn-details-row-title">
                        {{ $t("wallet.walletAddress") }}
                    </div>

                    <div class="txn-details-row-content primary-color-text">
                        <div id="copyValue">
                            {{ details.address }}
                        </div>
                        <button @click.capture="copy(2, details.address)" class="copy-btn relative" id="address">
                            <v-tooltip v-model="show[2]" :disabled="true" bottom>
                                <template v-slot:activator="{ props }">
                                    <img v-bind="props" src="@/assets/images/wallet/copy.svg" />
                                </template>
                                <span>{{ $t("wallet.copied") }}</span>
                            </v-tooltip>
                        </button>
                    </div>
                </div>

                <!-- NETWORK -->
                <div class="txn-details-row" v-if="details.network">
                    <div class="txn-details-row-title">{{ $t("wallet.network") }}</div>
                    <div class="txn-details-row-content">
                        {{ details.network }}
                    </div>
                </div>

                <!-- ADDRESS -->
                <div class="txn-details-row" v-if="details.from_address">
                    <div class="txn-details-row-title">
                        {{ $t("wallet.address") }}
                    </div>

                    <div class="txn-details-row-content primary-color-text">
                        <div id="copyValue">
                            {{ details.from_address }}
                        </div>
                        <button @click.capture="copy(2, details.from_address)" class="copy-btn relative" id="address">
                            <v-tooltip v-model="show[2]" :disabled="true" bottom>
                                <template v-slot:activator="{ props }">
                                    <img v-bind="props" src="@/assets/images/wallet/copy.svg" />
                                </template>
                                <span>{{ $t("wallet.copied") }}</span>
                            </v-tooltip>
                        </button>
                    </div>
                </div>

                <!-- TAG -->
                <div class="txn-details-row" v-if="details.tag">
                    <div class="txn-details-row-title">{{ $t("wallet.tag") }}</div>

                    <div class="txn-details-row-content primary-color-text">
                        <div id="copyValue">
                            {{ details.tag }}
                        </div>
                        <button @click.capture="copy(3, details.tag)" class="copy-btn relative" id="tag">
                            <v-tooltip v-model="show[3]" :disabled="true" bottom>
                                <template v-slot:activator="{ props }">
                                    <img v-bind="props" src="@/assets/images/wallet/copy.svg" />
                                </template>
                                <span>{{ $t("wallet.copied") }}</span>
                            </v-tooltip>
                        </button>
                    </div>
                </div>

                <!-- PENDING TXID -->
                <div class="txn-details-row" v-if="details.pendingTxID">
                    <div class="txn-details-row-title">
                        {{ $t("wallet.TxID") }}
                    </div>

                    <div class="txn-details-row-content gray-text text-capitalize">
                        {{ details.pendingTxID }}
                    </div>
                </div>

                <!-- TXID / HASH -->
                <div class="txn-details-row" v-if="details.hash">
                    <div class="txn-details-row-title">
                        {{ $t("wallet.TxID") }}
                    </div>

                    <div class="txn-details-row-content primary-color-text" id="txn-hash">
                        <div id="copyValue">
                            {{ details.hash }}
                        </div>
                        <button @click.capture="copy(4, details.hash)" class="copy-btn relative" id="hash">
                            <v-tooltip v-model="show[4]" :disabled="true" bottom>
                                <template v-slot:activator="{ props }">
                                    <img v-bind="props" src="@/assets/images/wallet/copy.svg" />
                                </template>
                                <span>{{ $t("wallet.copied") }}</span>
                            </v-tooltip>
                        </button>
                    </div>
                </div>

                <!-- DEPOSIT REFERENCE CODE -->
                <div class="txn-details-row" v-if="details.deposit_refCode">
                    <div class="txn-details-row-title">
                        {{ $t("Deposit.depReferenceCode") }}
                    </div>

                    <div class="txn-details-row-content primary-color-text relative" id="deposit_refCode">
                        <div id="copyValue">
                            {{ details.deposit_refCode }}
                        </div>
                        <button @click.capture="copy(5, details.deposit_refCode)" class="copy-btn" id="dep-refCode">
                            <v-tooltip v-model="show[5]" :disabled="true" bottom>
                                <template v-slot:activator="{ props }">
                                    <img v-bind="props" src="@/assets/images/wallet/copy.svg" />
                                </template>
                                <span>{{ $t("wallet.copied") }}</span>
                            </v-tooltip>
                        </button>
                    </div>
                </div>

                <!-- BENEFICIARY NAME -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'" v-if="details.beneficiary_name">
                    <div class="txn-details-row-title">{{ $t("Deposit.beneficiaryName") }}</div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.beneficiary_name }}
                    </div>
                </div>

                <!-- BENEFICIARY ADDRESS -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'" v-if="details.beneficiary_addr">
                    <div class="txn-details-row-title">{{ $t("Deposit.beneficiaryAddr") }}</div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.beneficiary_addr }}
                    </div>
                </div>

                <!-- ACCOUNT NAME -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'"
                    v-if="details.account_name && details.isFiat ? $store.state.preferences.platform != 1 ? true : details.isDeposit ? true : false : false">
                    <div class="txn-details-row-title">{{ $t("Deposit.accountName") }}</div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.account_name }}
                    </div>
                </div>

                <!-- BANK NAME -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'" v-if="details.bank_name">
                    <div class="txn-details-row-title">{{ $t("Deposit.bankName") }}</div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.bank_name }}
                    </div>
                </div>

                <!-- BANK -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'" v-if="details.bank">
                    <div class="txn-details-row-title text-capitalize">
                        {{ $t("Withdraw.bank") }}
                    </div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.bank }}
                    </div>
                </div>

                <!-- BANK ADDRESS -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'" v-if="details.bank_addr">
                    <div class="txn-details-row-title">{{ $t("Deposit.bankAddr") }}</div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.bank_addr }}
                    </div>
                </div>

                <!-- ACCOUNT NUMBER -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'" v-if="details.account_number && details.transaction_type !== 'withdrawal_fee'">
                    <div class="txn-details-row-title">{{ $t("Deposit.accountNumber") }}</div>

                    <div v-if="isCopy" :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'"
                        class="relative">
                        <div id="copyValue">
                            {{ details.account_number }}
                        </div>
                        <button @click.capture="copy(7, details.account_number)" class="copy-btn" id="account_number">
                            <v-tooltip v-model="show[7]" :disabled="true" bottom>
                                <template v-slot:activator="{ props }">
                                    <img v-bind="props" src="@/assets/images/wallet/copy.svg" />
                                </template>
                                <span>{{ $t("wallet.copied") }}</span>
                            </v-tooltip>
                        </button>
                    </div>

                    <div v-else :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.account_number }}
                    </div>
                </div>

                <!-- ACCOUNT NO. -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'" v-if="details.accNo">
                    <div class="txn-details-row-title">{{ $t("Withdraw.accNo") }}</div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.accNo }}
                    </div>
                </div>

                <!-- DEPOSIT SWIFT CODE -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'"
                    v-if="details.swift_code && details.isFiat && $store.state.preferences.platform == 2 && details.swift_code !== 'DuitNow'">
                    <div class="txn-details-row-title">{{ $t("Deposit.swiftCode") }}</div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.swift_code }}
                    </div>
                </div>

                <!-- DEPOSIT CURRENCY -->
                <div :class="isRow ? 'txn-details-row' : 'txn-details-col'" v-if="details.currency">
                    <div class="txn-details-row-title">{{ $t("Deposit.currency") }}</div>

                    <div :class="isRow ? 'txn-details-row-content' : 'txn-details-col-content'">
                        {{ details.currency }}
                    </div>
                </div>

                <!-- NETWORK FEE -->
                <div class="txn-details-row" v-if="details.network_fee || details.network_fee == '0'">
                    <div class="txn-details-row-title" v-if="details.amt_currency">{{ `${$t("Send.networkFee")}
                        (${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("Send.networkFee") }}</div>

                    <div class="txn-details-row-content">
                        <span class="text-gray">{{ ` ${details.network ? '' : details.percentage}` }}&nbsp;</span>{{
                            numberSeparator(details.network_fee, 8) }}
                    </div>



                </div>

                <!-- SERVICE CHARGE -->
                <div class="txn-details-row" v-if="details.service_charge">
                    <div class="txn-details-row-title">{{ $t("wallet.serviceCharge") }}</div>
                    <div class="txn-details-row-content">{{ details.service_charge }}</div>
                </div>


                <!-- AMOUNT -->
                <div class="txn-details-row" v-if="details.amount && !details.isFiat">
                    <div class="txn-details-row-title">{{ $t("wallet.amount") }} ({{ $store.state.preferences.platform
                        == 1 ? "MYR" :
                        "USD" }})</div>
                    <div class="txn-details-row-content">
                        {{ details.amount }}
                    </div>
                </div>


                <!-- CONVENIENCE FEE -->
                <div class="txn-details-row" v-if="details.conFee && !details.isFiat">
                    <div class="txn-details-row-title" v-if="details.fee_currency">{{ `${$t("Deposit.convenienceFees")}
                        (${details.fee_currency})`
                        }}</div>
                    <div class="txn-details-row-title" v-else-if="details.amt_currency">{{ `${$t("Deposit.convenienceFees")}
                        (${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("Deposit.convenienceFees") }}</div>

                    <div class="txn-details-row-content">
                        <span class="text-gray">{{ details.percentage && details.percentage }}&nbsp;</span>{{
                            details.conFee
                        }}
                    </div>
                </div>

                <!-- FEE -->
                <div class="txn-details-row" v-if="details.fee && !details.isFiat">
                    <div class="txn-details-row-title" v-if="details.fee_currency">{{ `${$t("Footer.Fees")}
                        (${details.fee_currency})`
                        }}</div>
                    <div class="txn-details-row-title" v-else-if="details.amt_currency">{{ `${$t("Footer.Fees")}
                        (${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("Footer.Fees") }}</div>

                    <div class="txn-details-row-content">
                        <span class="text-gray">{{ details.percentage && details.percentage }}&nbsp;</span>{{
                            details.fee
                        }}
                    </div>
                </div>

                <!-- PAy AMOUNT -->
                <div class="txn-details-row" v-if="details.pay_amt">
                    <div class="txn-details-row-title" v-if="details.amt_currency && !details.paid_currency">{{
                        `${$t("wallet.payAmt")}
                        (${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else-if="details.amt_currency && details.paid_currency">{{
                        `${$t("wallet.payAmt")} (${details.paid_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("wallet.payAmt") }}</div>

                    <div class="txn-details-row-conten text-bold">
                        {{ details.pay_amt }}
                    </div>
                </div>



                <!-- SEND AMOUNT -->
                <div class="txn-details-row" v-if="details.send_amt">
                    <div class="txn-details-row-title" v-if="details.amt_currency">{{ `${$t("Send.sendAmt")}
                        (${details.amt_currency})`
                        }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("Send.sendAmt") }}</div>

                    <div class="txn-details-row-content text-bold">
                        {{ details.send_amt }}
                    </div>
                </div>

                <!-- SENT AMOUNT -->
                <div class="txn-details-row" v-if="details.sent_amt">
                    <div class="txn-details-row-title" v-if="details.amt_currency">{{ `${$t("Send.sendAmt")}
                        (${details.amt_currency})`
                        }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("Send.sendAmt") }}</div>

                    <div class="txn-details-row-content text-bold">
                        {{ details.sent_amt }}
                    </div>
                </div>

                <!-- RECEIVED AMOUNT -->
                <div class="txn-details-row" v-if="details.received_amt">
                    <div class="txn-details-row-title" v-if="details.amt_currency && details.isBuy">{{
                        `${$t("wallet.receiveAmt")}
                        (${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else-if="details.amt_currency && !details.isBuy">{{
                        `${$t("wallet.receiveAmt")} (${details.paid_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("Withdraw.receivingAmt") }}</div>

                    <div class="txn-details-row-content text-bold">
                        {{ details.received_amt }}
                    </div>
                </div>



                <!-- DEPOSIT AMOUNT -->
                <div class="txn-details-row" v-if="details.deposit_amt">
                    <div class="txn-details-row-title">{{ $t("wallet.depositAmt") }}</div>

                    <div class="txn-details-row-content">
                        {{ details.deposit_amt }}
                    </div>
                </div>



                <!-- CLAWBACK -->
                <div class="txn-details-row" v-if="details.clawback">
                    <div class="txn-details-row-title">{{ $t("wallet.depReversed") }}</div>

                    <div class="txn-details-row-content">
                        {{ details.clawback }}
                    </div>
                </div>

                <!-- WITHDRAWAL FEES -->
                <div class="txn-details-row" v-if="details.withdrawal_fee">
                    <div class="txn-details-row-title">{{ $t("wallet.withdrawalFees") }} ({{
                        $store.state.preferences.platform == 1 ?
                            "MYR" : "USD" }})</div>

                    <div class="txn-details-row-content">
                        {{ details.withdrawal_fee }}
                    </div>
                </div>

                <!-- BANK CHARGES -->
                <div class="txn-details-row" v-if="details.bank_charges">
                    <div class="txn-details-row-title" v-if="details.amt_currency">{{ $t("wallet.bankCharges") }} {{
                        `(${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("wallet.bankCharges") }}</div>

                    <div class="txn-details-row-content">
                         {{ details.bank_charges }}
                    </div>
                </div>

                <!-- WITHDRAWAL AMOUNT -->
                <div class="txn-details-row" v-if="details.withdrawal_amt">
                    <div class="txn-details-row-title" v-if="details.amt_currency">{{ `${$t("wallet.withdrawalAmt")}
                        (${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("wallet.withdrawalAmt") }}</div>

                    <div class="txn-details-row-content text-bold">
                        {{ details.withdrawal_amt}}
                    </div>
                </div>

                <!-- RECEIVE AMOUNT -->
                <div class="txn-details-row" v-if="details.receive_amt">
                    <div class="txn-details-row-title" v-if="details.amt_currency">{{ `${$t("wallet.receiveAmt")}
                        (${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("wallet.receiveAmt") }}</div>

                    <div class="txn-details-row-content" v-if="details.amt_currency">
                        {{ details.receive_amt }}
                    </div>

                    <div class="txn-details-row-content" v-else>
                        {{ details.receive_amt }} {{ details.symbol }}
                    </div>
                </div>

                <!-- RECEIVE AMOUNT -->
                <div class="txn-details-row" v-if="details.receiving_amt">
                    <div class="txn-details-row-title">{{ $t("Withdraw.receivingAmt") }}</div>

                    <div class="txn-details-row-content text-bold">
                        {{ details.receiving_amt }}
                    </div>
                </div>

                <!-- TOTAL AMOUNT -->
                <div class="txn-details-row" v-if="details.total_amt">
                    <div class="txn-details-row-title" v-if="details.amt_currency">{{ `${$t("Deposit.totalAmtFiat")}
                        (${details.amt_currency})` }}</div>
                    <div class="txn-details-row-title" v-else>{{ $t("Deposit.totalAmt") }}</div>

                    <div class="txn-details-row-content text-bold">
                        {{ numberSeparator(details.total_amt) }}
                    </div>
                </div>

                <!-- PAYMENT REFERENCE -->
                <div class="txn-details-row" v-if="details.statement_ref && details.status === $t('Status.completed') && details.transaction_type !== 'withdrawal_fee'">
                    <div class="txn-details-row-title">{{ $t("Withdraw.payRef") }}</div>

                    <div class="txn-details-row-content">
                        {{ details.statement_ref }}
                    </div>
                </div>

                <!-- PLATFORM -->
                <div class="txn-details-row" v-if="details.platform">
                    <div class="txn-details-row-title">{{ $t("Profile.platform") }}</div>

                    <div class="txn-details-row-content">
                        {{ details.platform }}
                    </div>
                </div>

                <!-- NOTE -->
                <div class="txn-details-row" v-if="details.remark">
                    <div class="txn-details-row-title">{{ $t("Send.note") }}</div>

                    <div class="txn-details-row-content txn-line-break">
                        {{ details.remark }}
                    </div>
                </div>

                <!-- CREATED DATE -->
                <div class="txn-details-row" v-if="details.createdDate">
                    <div class="txn-details-row-title">{{ $t("AutoInvestPlan.createdDate") }}</div>

                    <div class="txn-details-row-content">
                        {{ details.createdDate }}
                    </div>
                </div>

                <!-- AUTO INVEST START TIME -->
                <div class="txn-details-row" v-if="details.autoInvestStart">
                    <div class="txn-details-row-title">{{ $t("AutoInvestPlan.autoInvestStart") }}</div>

                    <div class="txn-details-row-content">
                        {{ details.autoInvestStart }}
                    </div>
                </div>

                <!-- RECURRING CYCLE -->
                <div class="txn-details-row" v-if="details.cycle">
                    <div class="txn-details-row-title">{{ $t("AutoInvestPlan.recurringCycle") }}</div>

                    <div class="txn-details-row-content">
                        {{ details.cycle }}
                    </div>
                </div>

                <!-- ESTIMATED START TIME -->
                <div class="txn-details-row" v-if="details.startTime">
                    <div class="txn-details-row-title">{{ $t("AutoInvestPlan.estimatedStartTime") }}</div>

                    <div class="txn-details-row-content">
                        {{ details.startTime }}
                    </div>
                </div>

                <!-- TOTAL INVEST AMOUNT -->
                <div class="w-100" v-if="details.coinData && details.total_invested">
                    <div class="txn-details-row">
                        <div class="txn-details-row-title text-bold text-header">{{ $t("AutoInvestPlan.totalAmountInvested") }}</div>

                        <div class="txn-details-row-content text-bold text-header">
                            {{ details.total_invested + ' ' + details.invest_funds}}
                        </div>
                    </div>

                    <div class="txn-details-row-list" v-for="item, index in details.coinData" :key="index">
                        <ul class="txn-details-list">
                            <li class="txn-details-row-title text-bold text-header">{{ item.asset }}</li>
                            
                            <div class="txn-details-row-content gray-text">
                                    {{ item.quote_qty + ' ' + details.invest_funds}}
                            </div>
                        </ul>
                    </div>
                </div>

                <!-- AUTO INVEST FEES -->
                <div class="txn-details-row" v-if="details.investFees && details.dca_percentage && details.invest_funds">
                    <div class="txn-details-row-title">{{ $t("AutoInvestPlan.Fees", {percent: details.dca_percentage ?? 0}) }}</div>

                    <div class="txn-details-row-content">
                        ≈ {{ details.investFees + ' ' + details.invest_funds}}
                    </div>
                </div>
            </div>

                <!-- FIAT REMARK -->
                <RemarkContainer :isWarning="true" icon="profile/warning.svg" :text="details.fiat_remark"
                    :list="details.remark_list" :isTitleBold="details.remark_title_bold ?? false"
                    v-if="details.fiat_remark" />

                <button class="main-btn" id="txn-details-btn" :class="disabled ? 'grayscale' : ''"
                    @click="$emit('action')" :disabled="disabled" v-if="buttonText">
                    <span v-if="!isLoading">{{ buttonText }}</span>
                    <span class="spinner-border-sm spinner-border" v-else></span>
                </button>

                <button class="secondary-btn w-85" :class="disabled2 ? 'grayscale' : ''" @click="$emit('action2')"
                    :disabled="disabled2" v-if="buttonText2">
                    {{ buttonText2 }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isRow: { type: Boolean, default: true },
        isBackBtn: { type: Boolean, default: false },
        question: { type: Object },
        details: { type: Object },
        buttonText: { type: String, default: "" },
        buttonText2: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        disabled2: { type: Boolean, default: false },
        isLoading: { type: Boolean, default: false },
        isCopy: { type: Boolean, default: false },
        screenshot: {type: String, default: ""}
    },

    data() {
        return {
            show: [false, false, false, false, false, false, false],
        };
    },

    mounted() {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 20);
    },

    methods: {
        copy(id, value) {
            let arr = this.show;
            arr[id] = true;
            this.show = [];
            this.show = arr;

            const container = document.querySelector("#copyValue");
            this.$copyText(value.trim(), container);

            setTimeout(() => {
                let arr = this.show;
                arr[id] = false;
                this.show = [];
                this.show = arr;
            }, 3000);
        },
    },
};
</script>

<style>
.txn-details-transaction-type {
    text-transform: capitalize;
}

#txn-details.main-body {
    padding-bottom: 1rem;
}

.txn-invest-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;;
}

.txn-details-row-list {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
}

.txn-details-list {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2rem;
    margin-bottom: 0px;
}

.text-header {
    color: var(--header-text);
}

@media only screen and (max-width: 568px) {
    #txn-details #copyValue {
        max-width: 200px;
    }

    .txn-invest-image {
        width: 100%;
    }
}
</style>
