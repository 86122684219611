import axios from "axios";
import sha256 from "crypto-js/sha256";
import moment from "moment";

let PIXEL_TOKEN =
  "EAAJSB1LLYnUBO46BaXQWBR8KdHNweYtLgGA47JDHmqSsw0muz3yRc4CnRGoQfXFunw04UTVVvjv8c8BmygIBpxkQqw8JqdsRKYkwWwkqy5exZAN4xOLWKEA2fBZAIPtZB09nLHGw6eOF0NBNoQI9Hhn3UO7HdQapeUKURHsEXzJYlxvhWlU9t5GTa59DqEZBuAZDZD";
let PIXEL_ID = 1001704377955674;
let API_VERSION = "v21.0";
let eventType = ["ViewContent", "Purchase", "CompleteRegistration"];

const state = {};

const mutations = {};

const actions = {
  async sendPixelEvent({ rootState }, params) {
    if (process.env.NODE_ENV === "production") {
      let eventParams = {
        data: [
          {
            event_name: eventType[params.type],
            event_time: moment().format("X").toString(),
            action_source: "website",
            user_data: {
              em: [
                rootState.user.email
                  ? sha256(rootState.user.email).toString()
                  : params.email
                  ? sha256(params.email).toString()
                  : null,
              ],
            },
            custom_data:
              params.type == 1
                ? {
                    currency: params.symbol ? params.symbol : null,
                    value: params.amount ? params.amount : null,
                  }
                : [],
            original_event_data: {
              event_name: eventType[params.type],
              event_time: moment().format("X").toString(),
            },
          },
        ],
        //   test_event_code: "TEST89880",
      };
      let PIXEL_LINK = `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${PIXEL_TOKEN}`;
      let response = await axios.post(PIXEL_LINK, eventParams);
    } else {
      return;
    }
  },
};

export const pixel = {
  namespaced: true,
  state,
  mutations,
  actions,
};
