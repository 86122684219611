<template>
  <v-app id="app" :key="$route.path">
    <KycTopRibbon />
    <MainHeader />
    <MainSideBar/>
    <!-- <MaintenanceBanner /> -->
    <div
      class="h-router-view"
      :class="!$route ? 'onboarding-outer-container' : ''"
      :style="{
        'background-color':
          theme === 'dark-theme' &&
          ($route && $route.name && $route.name.includes('ExchangeView'))
          ? '#0a142e'
          : 'transparent'
        }"
    >
      <router-view />
    </div>

    <CookieNoticeModal @enable="enableCookies" />

    <!-- REQUEST BIND 2FA MODAL -->
    <IconTextModal
      :dialog="$store.state.user.showRequest2faModal"
      image="modal/warning.png"
      :title="$t('Modal.kindlyEnable2FA')"
      titleClass="fail-text"
      :description="$t('Modal.kindlyEnable2FADesc')"
      :buttonText="$t('Modal.bind2FAnow')"
      @action="bind2FA"
      @close="request2FAClose"
    />

    <!-- REQUEST KYC MODAL -->
    <IconTextModal
      modalId="modal-status-requestkyc"
      :dialog="$store.state.user.showRequestKycModal"
      :image="
        $store.state.user.kyc_status === 'pending'
          ? 'modal/pending3.png'
          : 'modal/warning.png'
      "
      :title="
        $store.state.user.kyc_status === 'pending'
          ? $t('Modal.kycPendingTitle')
          : $t('Modal.kycIncompleteTitle')
      "
      :titleClass="$store.state.user.kyc_status !== 'pending' ? 'fail-text' : ''"
      :description="
        $store.state.user.kyc_status === 'pending'
          ? $t('Modal.kycPendingDesc')
          : $t('Modal.kycIncompleteDesc')
      "
      :buttonText="$store.state.user.kyc_status !== 'pending' ? $t('Modal.proceedToKyc') : ''"
      @close="requestkycClose"
      @action="routerKyc"
    />

    <!-- PROHIBITED COUNTRIES MODAL -->
    <IconTextModal
      :dialog="$store.state.kyc.showProhibitedModal"
      image="modal/warning.png"
      :title="$t('Modal.prohibited')"
      titleClass="fail-text"
      :description="$t('Modal.prohibitedDesc')"
      :buttonText="$t('Button.skipVerification')"
      :buttonText2="$t('Button.learnMore')"
      @action="$store.dispatch('kyc/showProhibitedModal', false);"
      @action2="openLinkInNewTab('https://support.hata.io/support/solutions/articles/150000149943-hata-supported-countries-where-is-it-not-available-')"
      :isCloseBtn="false"
    />

    <IconTextModal
      :dialog="$store.state.user.showSwitchErrorModal"
      image="modal/warning.png"
      :title="$t('Modal.switchPlatformError')"
      titleClass="fail-text"
      :buttonText="$t('Button.OK')"
      @action="$store.dispatch('user/showSwitchErrorModal', false);"
      @close="$store.dispatch('user/showSwitchErrorModal', false);"
      :isCloseBtn="false"
    />

    <IconTextModal
      :dialog="$store.state.user.showPKModal"
      image="profile/gradient-passkey.png"
      :title="$t('Modal.passkeySetup')"
      :description="$t('Modal.passkeySetupDesc')"
      :buttonText="$t('Modal.enablePasskey')"
      @action="passKeyClose(1)"
      @close="passKeyClose"
    />

    <IconTextModal
        :dialog="isReturn"
        image="modal/warning.png"
        titleClass="fail-text"
        :title="$t('Payment.cancelTransaction')"
        :description="$t('Payment.confirmCancel')"
        :buttonText="$t('Button.confirm')"
        @action="returnToMerchant"
        :isCloseBtn="false"
        @close="$store.state.preferences.setReturnMerchant = false"
      />

      <!-- <v-dialog v-model="showBuddyBonus" max-width="500">
        <button @click="showBuddyBonus=false" class="buddy-bonus-modal modal-close-btn">
          <span>{{ $t("Button.close") }}</span>
          <img src="@/assets/images/modal/white-close-circle.png" width="22px" />
        </button>
        <img :src="require(`@/assets/images/BuddyBonus_${$store.state.user.locale ?? 'en'}.png`)" @click="navigateReward"/>
      </v-dialog> -->

    <ScrollToTop />

    <MainFooter />
  </v-app>
</template>

<script>
import MainFooter from "./components/main/MainFooter.vue";
import MainHeader from "./components/main/MainHeader.vue";
import MainSideBar from "./components/main/MainSideBar.vue";
import KycTopRibbon from "./components/main/KycTopRibbon.vue";
import MaintenanceBanner from "./components/main/MaintenanceBanner.vue";
import CookieNoticeModal from "./components/modal/CookieNoticeModal.vue";
import ScrollToTop from "./components/main/ScrollToTop.vue";
import {useRouter} from "vue-router";

export default {
  name: "App",

  metaInfo: {
    title: "Hata - Global Crypto Exchange | Buy & Sell Crypto With Fiat",
    meta: [
      { name: "description", content: "Regulated by Securities Commision of Malaysia and the Labuan Financial Services Authority, Hata is a digital asset platform that offers an online exchange and offline OTC service." },
      { name: "keywords", content: "Malaysian, Crypto, Exchange, Hata" },
    ]
  },

  components: {
    MainHeader,
    MainFooter,
    KycTopRibbon,
    CookieNoticeModal,
    MainSideBar,
    // MaintenanceBanner,
    ScrollToTop,
  },

  data() {
    return {
      showModal: false,
      notificationInterval: null,
      notificationTimer: 3000,
      // showBuddyBonus: false,
      userId: "",
    };
  },

  computed: {
    theme() {
      return this.$store.state.preferences.theme;
    },

    deviceWidth() {
      return this.$store.state.preferences.deviceWidth;
    },

    isReturn() {
      return this.$store.state.preferences.setReturnMerchant;
    },
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.updateDeviceWidth);
    window.removeEventListener("scroll", this.updateDeviceWidth);

    if(this.notificationInterval) {
      this.stopNotifications();
    }
  },

  async mounted() {
    this.$store.dispatch("pixel/sendPixelEvent", { type: 0 });
    this.$nextTick(() => {
      window.addEventListener("resize", this.updateDeviceWidth);
      window.addEventListener("scroll", this.updateDeviceWidth);
    });

    // if (this.$store.state.preferences.showCookieModal && this.$route.name !== "CookiePolicy") {
    //   this.$store.dispatch("preferences/showCookieModal", true);
    // }

    // if(!this.$store.state.auth.isLoggedIn) {
    //   useRouter().isReady().then(() => {
    //     if(!this.$route.name.includes('VerifyTwoFa') &&
    //       !this.$route.name.includes('AppleLoading') &&
    //       !this.$route.name.includes('VerifyEmailOtp') &&
    //       !this.$route.name.includes('loading') &&
    //       !this.$route.name.includes('EmailVerifiedView') &&
    //       !this.$route.name.includes('VerifyPasskey')) {
    //         this.showBuddyBonus = true;
    //     }
    //   })
    // }

    this.checkLogin();
  },

  watch: {
    $route: function () {
      const prefix = this.$store.getters["preferences/routePrefix"];
      if(!this.$route.meta.isCommon && !this.$route.name.includes(prefix)) {
        this.navigateToRoute(this.$route.name);
      }

      this.checkLogin();

      if (this.$store.state.auth.isLoggedIn) {
        if (this.$route.meta.requiresKyc && this.$store.state.user.kyc_status !== "approved") {
          this.$store.dispatch("user/showRequestKycModal", true);
          return;
        }

        if (this.$route.meta.requires2FA && !this.$store.state.user.twofa_enabled && !this.$store.state.user.passkey_enabled) {
          this.$store.dispatch("user/showRequest2faModal", true);
          return;
        }
      }
    },

    deviceWidth: {
      handler() {
        if(this.deviceWidth > 1200) {
          this.$store.dispatch("preferences/setOpenSidebar", false);
        }
      }
    }
  },

  methods: {
    updateDeviceWidth() {
      this.$store.dispatch("preferences/setDeviceWidth");
    },

    enableCookies() {
      this.$cookies.set("isCookieRead", true, Infinity);
      this.$store.dispatch("preferences/showCookieModal", false);
    },

    checkLogin() {
      if (this.$store.state.auth.isLoggedIn) {
        if(this.$store.state.user.referral_id === "") {
          this.$store.dispatch("user/getProfile");
        }

        if (this.$route.meta.requiresKyc && this.$store.state.user.kyc_status !== "approved") {
          this.$store.dispatch("user/showRequestKycModal", true);
          return;
        }

        if (this.$route.meta.requires2FA && !this.$store.state.user.twofa_enabled && !this.$store.state.user.passkey_enabled) {
          this.$store.dispatch("user/showRequest2faModal", true);
          return;
        }

        if(!this.notificationInterval) {
          this.getNotification();
        }

        if(this.$store.state.user.id && this.$store.state.user.kyc_status === 'approved' && !this.$store.state.user.passkey_enabled) {
          this.userId = `UP-${this.$store.state.user.id}`;
          let userPass = this.$cookies.isKey(this.userId);

          if (!userPass) {
              this.$store.dispatch("user/showPKModal", true);
          }

        }
        
        // if(this.$route.name !== undefined) {
        //   if(this.$store.state.user.id && 
        //     !this.$route.name.includes('VerifyTwoFa') &&
        //     !this.$route.name.includes('AppleLoading') &&
        //     !this.$route.name.includes('VerifyEmailOtp') &&
        //     !this.$route.name.includes('loading') &&
        //     !this.$route.name.includes('EmailVerifiedView') &&
        //     !this.$route.name.includes('VerifyPasskey')) {
        //     let bbId = `BB-${this.$store.state.user.id}`;
        //     if(!this.$cookies.isKey(bbId)) {
        //       this.showBuddyBonus = true;
        //       this.$cookies.set(bbId, '', Infinity);
        //       }
        //   }
        // }
      } else {
        if(this.notificationInterval) {
          this.stopNotifications();
        }
        
        localStorage.removeItem("data");
        this.$store.dispatch("user/initializeUser", null, { root: true });
      }

      // if(!this.$store.state.MY_referral.campaignEndTime && !this.$store.state.MY_referral.rewardLastDay) {
      //   this.$store.dispatch("MY_referral/getBuddyBonusTime");
      // }
    },

    async getNotification() {
      const asyncInterval = async (callback, delay) => {
        let stop = false;

        const interval = async () => {
          if (!stop) {
            await callback();
            setTimeout(interval, delay);
          }
        };

        await interval();

        return () => {
          stop = true;
        };
      };

      this.notificationInterval = await asyncInterval(async () => {
        this.$store.dispatch("user/getUnreadNotification");
      }, 1800000); // 30 mins
    },

    stopNotifications() {
      this.notificationInterval();
      this.notificationInterval = null;
    },

    requestkycClose() {
      if (this.$route.meta.requiresKyc) {
        this.$router.go(-1);
      } else {
        this.$store.dispatch("user/showRequestKycModal", false);
      }
    },

    passKeyClose(actionType) {
      if(actionType) {
        this.navigateToRoute('addPasskeys');
      }
      // const userId = `UP-${this.$store.state.user.id}`;
      this.$cookies.set(this.userId, "", Infinity);
      this.$store.dispatch('user/showPKModal', false)
    },

    routerKyc() {
      if (this.$store.state.user.business) {
        this.navigateToRoute("BusinessKyc");
      } else {
        this.navigateToRoute("KycView");
      }
      this.$store.dispatch("user/showRequestKycModal", false);
    },

    request2FAClose() {
      if (this.$route.meta.requires2FA) {
        this.$store.dispatch("user/showRequest2faModal", false);
        this.$router.go(-1);
      } else {
        this.$store.dispatch("user/showRequest2faModal", false);
      }
    },

    bind2FA() {
      this.$store.dispatch("user/showRequest2faModal", false);
      // This modal only will show if no 2FA yet, so safe to redirect.
      this.navigateToRoute("addPasskeys");
    },

    // navigateReward() {
    //   this.showBuddyBonus = false;
    //   if(this.$store.state.auth.isLoggedIn) {
    //     if(this.$store.state.preferences.platform === '1') {
    //         return this.navigateToRoute('RewardView', "", { tab: 'buddy' });
    //     } else {
    //         return this.navigateToRoute('RewardView');
    //     }
    //   } else {
    //     this.navigateToRoute("BuddyBonusView");
    //   }
    // }
  },
};
</script>

<style>
@import "@/css/styles.css";

#app {
  font-family: "Avenir";
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--header-text);
  text-align: left;
  font-size: 1rem;
  /* background: var(--hover-color); */
  background: var(--gray10);
}

.modal-dialog,
.v-btn {
  font-family: "Avenir";
  font-weight: 500;
  letter-spacing: normal !important;
}

/* import font */
@font-face {
  font-family: "Avenir";
  font-weight: 400;
  src: url("./assets/fonts/avenir/Avenir-Book.ttf");
}

@font-face {
  font-family: "Avenir";
  font-weight: 500;
  src: url("./assets/fonts/avenir/Avenir-Medium.ttf");
}

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  src: url("./assets/fonts/avenir/Avenir-Heavy.ttf");
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(127, 143, 164, 0.4);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

button {
  cursor: pointer;
}

button:hover {
  opacity: 0.85;
}

button:focus-visible {
  outline: none;
}

.h-router-view {
  min-height: 95vh;
}

.buddy-bonus-modal.modal-close-btn {
  top: -30px;
}

.buddy-bonus-modal > span{
  padding: 0px 5px;
  color: var(--white);
}
</style>
